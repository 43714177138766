import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, IconButton } from '@mui/material';
import { CSSProperties, useCallback, useState } from 'react';
import { useDraggableImage } from '../hooks';

interface ControlsProps {
  size: 'small' | 'medium' | 'large';
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const Controls = ({ size, onZoomIn, onZoomOut }: ControlsProps) => {
  return (
    <Box position="absolute" top="0%" right="0%">
      <IconButton onClick={onZoomIn} style={{ backgroundColor: 'white' }}>
        <ZoomInIcon fontSize={size} />
      </IconButton>
      <IconButton onClick={onZoomOut} style={{ backgroundColor: 'white' }}>
        <ZoomOutIcon fontSize={size} />
      </IconButton>
    </Box>
  );
};

interface ImgProps {
  src: string;
  style?: Omit<CSSProperties, 'transform' | 'zoom'>;
  alt?: string;
}

const clearScale = (transform: string) => transform.replaceAll(/scale\(.+\)/g, '');

export const Img = ({ src, style, alt = '' }: ImgProps) => {
  const [scale, setScale] = useState(1);
  const [size, setSize] = useState<DOMRect | null>(null);
  const [imageElement, setImageElement] = useState<HTMLImageElement>();
  const [setImage] = useDraggableImage();

  const imageRef = useCallback(
    (node: HTMLImageElement) => {
      if (node !== null && node.getBoundingClientRect().height && node.getBoundingClientRect().width) {
        setSize(node.getBoundingClientRect());
        setImageElement(node);
        setImage(node);
      }
    },
    [setImage]
  );

  const onZoomIn = () => setScale((prev) => Math.min(prev + 0.1, 3));
  const onZoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.3));

  return (
    <Box
      position="relative"
      height={size?.height || 'auto'}
      width={size?.width || 'auto'}
      overflow="hidden"
      style={{ objectFit: 'contain' }}
    >
      <img
        src={src}
        alt={alt}
        style={{ ...style, transform: `${clearScale(imageElement?.style.transform ?? '')} scale(${scale})` }}
        ref={imageRef}
        onLoad={(event: React.SyntheticEvent<HTMLImageElement>) => imageRef(event.target as any)}
      />
      <Controls size="medium" onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
    </Box>
  );
};
